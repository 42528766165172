import React from 'react';

const Header = () => {
  return (
    <header>
      <img src="santacroce_transparent.png" alt="Santacroce Tech" className="logo"></img>
    </header>
  );
};

export default Header;
